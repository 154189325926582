<template>
  <section class="h-100">

    <div style="padding: 40px;">
      <div class="m-auto" style="display: inline-block;">
        <img
          src="@/assets/images/relatus_orange_logo_horizontal.png"
          style="max-height: 60px;"
        >
      </div>

      <div style="display: inline-block;float: right;margin-top: 13px;">
        <span class="uppercase">Pre-register</span>
        <img
          src="@/assets/images/menu.svg"
          style="display: inline-block;margin-left: 20px;"
        >
      </div>
    </div>

    <div class="flex h-screen text-center" style="height:80vh;">

      <div class="m-auto pb-10">

        <div class="mb-12 relative">
          <router-link
            :to="{name: 'registration.v2.signup.name'}"
            class="absolute left-0"
          >
            <img
              src="@/assets/images/icons/ic_chevron_left.svg"
              class="inline-block"
            > Back
          </router-link>

          <span>Approximately 5 min. to complete</span>
        </div>

        <div class="mt-9" v-if="!emailSent">
          <h3 class="text text-center text-neutral mb-9">
            Nice to meet you {{ firstName }}
          </h3>

          <form
            class="mt-10 mb-20"
            @submit.prevent="register"
          >

            <h3 class="text text-center text-black mb-10">
              What’s your personal email address?
            </h3>

            <div
              v-if="errorMessage"
              class="flex items-center text-dark text-sm mb-5 alert-error"
              role="alert"
            >
              <img
                src="@/assets/images/ic_danger.svg"
                class="mr-2"
              >
              <p>{{ errorMessage }}</p>
            </div>

            <div class="mb-3">
              <input
                v-model="emailAddress"
                type="text"
                class="form-control w-3/5"
                placeholder="Email Address"
                required
                style='margin-right: 20px;'
              >

              <p class="m-auto mt-5 max-w-sm text-left text-neutral">Which email is the one you use for life? Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>

            <p class="m-auto mt-5 max-w-sm text-left">
              <a href="#" class="text-primary font-bold">Why do you need my email address?</a>
            </p>

            <div class="mt-10 pt-10 text-center">
              <button
                type="submit"
                @click.prevent="submit()"
                class="btn btn-primary inline-block w-2/5" :class="{ 'btn-primary-outline': !emailAddress }"
              >
                Onwards
              </button>
            </div>
          </form>
        </div>

        <div class="mt-9" v-if="emailSent">
          <h3 class="text text-center text-neutral mb-9">
            Got it, Thanks!
          </h3>

          <h3 class="text text-center text-black mb-10 max-w-2xl">
            We sent you a magic link to your email <span>{{ emailAddress }}</span>
          </h3>

          <p class="m-auto mt-5 max-w-md text-neutral">Please check your email for a link to verify your identity.</p>

          <p class="m-auto mt-5 max-w-sm">
            <a href="#" class="text-primary font-bold">I didn’t get the link. Resend.</a>
          </p>

          <div class="mt-10 pt-10 text-center">
            <router-link
              :to="{name: 'registration.v2.signup.phone'}"
              class="btn btn-primary inline-block w-2/5"
            >
              Onwards
            </router-link>
          </div>
        </div>

        <div class="mt-10 text-center">
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot active"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
          <span class="dot"></span>
        </div>

      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SignupEmail',
  data() {
    return {
      firstName: '',
      emailAddress: '',
      errorMessage: null,
      emailSent: false
    };
  },
  methods: {
    submit() {
      if (!this.emailAddress || this.emailAddress === "") {
        this.errorMessage = 'Please fill in the email address field and try again.'
        return false;
      } else if (!this.validEmail(this.emailAddress)) {
        this.errorMessage = 'Hmm, looks like that’s an invalid email. Please try again.'
        return false;
      }

      localStorage.setItem('emailAddress', this.emailAddress);

      this.errorMessage = null;
      this.emailSent = true;
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  },
  mounted() {
    let firstName = localStorage.getItem('firstname');
    let emailAddress = localStorage.getItem('emailAddress');

    if (firstName && firstName !== '') {
      this.firstName = firstName;
    }
    if (emailAddress && emailAddress !== '') {
      this.emailAddress = emailAddress;
    }
  }
}
</script>

<style scoped>
h3 {
  font-family: 'Helvetica';
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 1px;
  font-weight: bold;
}
h3 > strong {
  font-weight: bold;
}
</style>
